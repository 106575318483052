import notificationsSelector from "../selectors/notificationsSelector";

export default function notificationReducer(
  state = notificationsSelector,
  { type, payload }
) {
  switch (type) {
    case "SNACKBAR_SUCCESS": {
      return {
        ...state,
        success: payload,
      };
    }
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        success: {
          isOpen: false,
          message: "",
          title: "",
          type: "",
        },
      };
    default:
      return state;
  }
}
