/* eslint-disable no-unused-vars */
import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

const UPAC = ({ control, errors, onNextClick }) => {
  return (
    <>
      <Typography sx={{ mb: 2 }} variant="h3">
        Dados da UPAC
      </Typography>
      <Controller
        name="n_registo"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            placeholder="Verificar no certificado MCP"
            fullWidth
            autoComplete="off"
            label="Nº Registo"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.n_registo}
            {...field}
            {...(errors.n_registo && {
              error: true,
              helperText: errors.n_registo?.message,
            })}
          />
        )}
      />
      <Controller
        name="potencia"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          pattern: {
            value: /^[0-9]*$/,
            message: "Apenas dígitos são permitidos",
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Potência de injeção na RESP (kVA)"
            sx={{ width: "48%", marginBottom: 1 }}
            inputProps={{
              onInput: (e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
              },
            }}
            inputRef={ref}
            error={!!errors.potencia}
            {...field}
            {...(errors.potencia && {
              error: true,
              helperText: errors.potencia?.message,
            })}
          />
        )}
      />
      <Controller
        name="cpe_consumo"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          validate: (value) => {
            if (!/^.{14}$/.test(value)) {
              return "O CPE de consumo deve ter 14 caracteres.";
            }
            return true; // Validation passed
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="CPE de consumo"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            inputProps={{
              maxLength: 14,
            }}
            InputProps={{
              startAdornment: "PT0002",
            }}
            error={!!errors.cpe_consumo}
            {...field}
            {...(errors.cpe_consumo && {
              error: true,
              helperText: errors.cpe_consumo?.message,
            })}
          />
        )}
      />
      <Controller
        name="cpe_producao"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          validate: (value) => {
            if (!/^.{14}$/.test(value)) {
              return "O CPE de consumo deve ter 14 caracteres.";
            }
            return true; // Validation passed
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="CPE de produção"
            sx={{
              width: "48%",
              marginBottom: 1,
            }}
            inputRef={ref}
            inputProps={{
              maxLength: 14,
            }}
            InputProps={{
              startAdornment: "PT0002",
            }}
            error={!!errors.cpe_producao}
            {...field}
            {...(errors.cpe_producao && {
              error: true,
              helperText: errors.cpe_producao?.message,
            })}
          />
        )}
      />
      <Controller
        name="morada_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Morada"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.morada_upac}
            {...field}
            {...(errors.morada_upac && {
              error: true,
              helperText: errors.morada_upac?.message,
            })}
          />
        )}
      />
      <Controller
        name="localidade_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Localidade"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.localidade_upac}
            {...field}
            {...(errors.localidade_upac && {
              error: true,
              helperText: errors.localidade_upac?.message,
            })}
          />
        )}
      />
      <Controller
        name="cod_postal_upac"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          pattern: {
            value: /^\d{4}-\d{3}$/,
            message: "O Código Postal deve ter o formato",
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Código Postal"
            sx={{ width: "48%", marginBottom: 1 }}
            inputRef={ref}
            inputProps={{
              maxLength: 8,
              onInput: (e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
                e.target.value = e.target.value.replace(
                  /(\d{4})(?=\d)/g,
                  "$1-"
                );
              },
            }}
            error={!!errors.cod_postal_upac}
            {...field}
            {...(errors.cod_postal_upac && {
              error: true,
              helperText: errors.cod_postal_upac?.message,
            })}
          />
        )}
      />
      <Controller
        name="concelho_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Concelho"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.concelho_upac}
            {...field}
            {...(errors.concelho_upac && {
              error: true,
              helperText: errors.concelho_upac?.message,
            })}
          />
        )}
      />
      <Controller
        name="distrito_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Distrito"
            sx={{ width: "48%", marginBottom: 1 }}
            inputRef={ref}
            error={!!errors.distrito_upac}
            {...field}
            {...(errors.distrito_upac && {
              error: true,
              helperText: errors.distrito_upac?.message,
            })}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onNextClick}
        sx={{
          my: 2,
          backgroundColor: "#05b6b7",
          borderRadius: "25px",
          color: "white",
        }}
        size="large"
      >
        Seguinte
        <span
          style={{
            display: "flex",
          }}
        >
          <ChevronRight />
        </span>
      </Button>
    </>
  );
};

export default UPAC;
