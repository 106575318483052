import axios from "axios";
import BASE_URL from "./BASE_URL";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({
  basename: "/",
  forceRefresh: true,
});

const Instance = () => {
  const instance = axios.create({
    baseURL: `${BASE_URL}`,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log(error.response);
      switch (error.response.status) {
        case 401:
          history.push("/");
          window.location = "/";

          break;

        default:
          console.log("From Interceptor");
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default Instance;
