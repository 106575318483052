/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { CalendarToday, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  CalendarIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ptLocale from "date-fns/locale/pt";

const Dados = ({
  control,
  errors,
  onNextClick,
  checkedTipo,
  handleChangeTipo,
  checkedMorada,
  handleChangeCheckMorada,
  checkedContacto,
  handleChangeCheckContacto,
  checkedTermos,
  handleChangeCheckTermos,
  validade,
  handleChangeValidade,
}) => {
  return (
    <>
      <Controller
        name="checkbox_p_e"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTipo === "particular"}
                  onChange={() => handleChangeTipo("particular")}
                  value="particular"
                />
              }
              label="Particular"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTipo === "empresa"}
                  onChange={() => handleChangeTipo("empresa")}
                  value="empresa"
                />
              }
              label="Empresa"
            />
          </FormGroup>
        )}
      />
      {checkedTipo == "particular" && (
        <>
          <Controller
            name="nome"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome}
                {...field}
                {...(errors.nome && {
                  error: true,
                  helperText: errors.nome?.message,
                })}
              />
            )}
          />
          <Controller
            name="apelido"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Apelido"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.apelido}
                {...field}
                {...(errors.apelido && {
                  error: true,
                  helperText: errors.apelido?.message,
                })}
              />
            )}
          />
          <Controller
            name="telemovel"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Telemóvel"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.telemovel}
                {...field}
                {...(errors.telemovel && {
                  error: true,
                  helperText: errors.telemovel?.message,
                })}
              />
            )}
          />
          <Controller
            name="nif"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIF"
                sx={{ width: "48%", marginBottom: 1 }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.nif}
                {...field}
                {...(errors.nif && {
                  error: true,
                  helperText: errors.nif?.message,
                })}
              />
            )}
          />
          <Controller
            name="cc"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{8}$/,
                message: "Deve ter 8 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{8}$/.test(value)) {
                  return "O nº CC/BI deve ter 8 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nº de CC/BI"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 8,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.cc}
                {...field}
                {...(errors.cc && {
                  error: true,
                  helperText: errors.cc?.message,
                })}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <Controller
              name="validade_doc"
              control={control}
              rules={{
                required: { value: true, message: "Campo Obrigatório" },
              }}
              render={({ field: { ref, ...field } }) => (
                <>
                  <DatePicker
                    {...field}
                    sx={{
                      width: "48%",
                      mb: 1,
                    }}
                    slotProps={{
                      textField: {
                        variant: "standard",
                      },
                    }}
                    minDate={new Date()}
                    format="dd/MM/yyyy"
                    label="Validade do documento"
                    value={validade}
                    onChange={handleChangeValidade}
                    inputRef={ref}
                    error={!!errors.validade_doc}
                    {...(errors.validade_doc && {
                      error: true,
                      helperText: errors.validade_doc?.message,
                    })}
                    renderInput={(params) => (
                      <>
                        <TextField {...params} />
                      </>
                    )}
                  />
                </>
              )}
            />
          </LocalizationProvider>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Endereço de email inválido",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Email"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.email}
                {...field}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email?.message,
                })}
              />
            )}
          />
          <Controller
            name="iban"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]*$/,
                message: "Apenas dígitos são permitidos",
              },
              validate: (value) => {
                if (!/^\d{21}$/.test(value)) {
                  return "O IBAN deve ter 21 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="IBAN"
                inputProps={{
                  maxLength: 21,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                  startAdornment: "PT50",
                }}
                InputProps={{
                  startAdornment: "PT50",
                }}
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.iban}
                {...field}
                {...(errors.iban && {
                  error: true,
                  helperText: errors.iban?.message,
                })}
              />
            )}
          />
          <Controller
            name="checkbox_morada"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedMorada}
                      onChange={handleChangeCheckMorada}
                      color="primary"
                    />
                  }
                  label="A morada fiscal é diferente do CPE"
                />
              </FormGroup>
            )}
          />
          {checkedMorada && (
            <>
              <Controller
                name="morada"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Morada"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.morada}
                    {...field}
                    {...(errors.morada && {
                      error: true,
                      helperText: errors.morada?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="localidade"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Localidade"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.localidade}
                    {...field}
                    {...(errors.localidade && {
                      error: true,
                      helperText: errors.localidade?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="cod_postal"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                  pattern: {
                    value: /^\d{4}-\d{3}$/,
                    message: "O Código Postal deve ter o formato",
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Código Postal"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    inputProps={{
                      maxLength: 8,
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        e.target.value = e.target.value.replace(
                          /(\d{4})(?=\d)/g,
                          "$1-"
                        );
                      },
                    }}
                    error={!!errors.cod_postal}
                    {...field}
                    {...(errors.cod_postal && {
                      error: true,
                      helperText: errors.cod_postal?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="concelho"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Concelho"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.concelho}
                    {...field}
                    {...(errors.concelho && {
                      error: true,
                      helperText: errors.concelho?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="distrito"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Distrito"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    error={!!errors.distrito}
                    {...field}
                    {...(errors.distrito && {
                      error: true,
                      helperText: errors.distrito?.message,
                    })}
                  />
                )}
              />
            </>
          )}
          <Typography sx={{ mt: 2 }} variant="body2">
            Como prefere ser contactado?
          </Typography>
          <Controller
            name="checkbox_contacto"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Selecione um método de contacto",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "email"}
                        onChange={() => handleChangeCheckContacto("email")}
                        value="email"
                      />
                    }
                    label="E-mail"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "contacto"}
                        onChange={() => handleChangeCheckContacto("contacto")}
                        value="contacto"
                      />
                    }
                    label="Contacto Telefónico"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "postal"}
                        onChange={() => handleChangeCheckContacto("postal")}
                        value="postal"
                      />
                    }
                    label="Correio Postal"
                  />
                </FormGroup>
                <FormHelperText sx={{ mb: 1, color: "#d32f2f" }}>
                  {errors.checkbox_contacto &&
                    errors.checkbox_contacto?.message}
                </FormHelperText>
              </>
            )}
          />
          <Controller
            name="checkbox_termos"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Tem de aceitar os termos e condições",
              },
            }}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedTermos}
                      onChange={handleChangeCheckTermos}
                      color="primary"
                    />
                  }
                  label="Aceito os termos e condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
                />
                <FormHelperText sx={{ mb: 1, color: "#d32f2f" }}>
                  {errors.checkbox_termos && errors.checkbox_termos?.message}
                </FormHelperText>
              </FormGroup>
            )}
          />
        </>
      )}
      {checkedTipo == "empresa" && (
        <>
          <Controller
            name="nome_empresa"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome Empresa"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome_empresa}
                {...field}
                {...(errors.nome_empresa && {
                  error: true,
                  helperText: errors.nome_empresa?.message,
                })}
              />
            )}
          />
          <Controller
            name="cod_certidao_permanente"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Código da certidão permanente"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.cod_certidao_permanente}
                {...field}
                {...(errors.cod_certidao_permanente && {
                  error: true,
                  helperText: errors.cod_certidao_permanente?.message,
                })}
              />
            )}
          />
          <Controller
            name="nipc"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIPC"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                error={!!errors.nirc}
                {...field}
                {...(errors.nirc && {
                  error: true,
                  helperText: errors.nirc?.message,
                })}
              />
            )}
          />
          <Controller
            name="cae"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="CAE"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.cae}
                {...field}
                {...(errors.cae && {
                  error: true,
                  helperText: errors.cae?.message,
                })}
              />
            )}
          />
          <Controller
            name="nome_rep_legal"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome do representante legal"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome_rep_legal}
                {...field}
                {...(errors.nome_rep_legal && {
                  error: true,
                  helperText: errors.nome_rep_legal?.message,
                })}
              />
            )}
          />
          <Controller
            name="telemovel"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Telemóvel"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.telemovel}
                {...field}
                {...(errors.telemovel && {
                  error: true,
                  helperText: errors.telemovel?.message,
                })}
              />
            )}
          />
          <Controller
            name="nif"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIF"
                sx={{ width: "48%", marginBottom: 1 }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.nif}
                {...field}
                {...(errors.nif && {
                  error: true,
                  helperText: errors.nif?.message,
                })}
              />
            )}
          />
          <Controller
            name="cc"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{8}$/,
                message: "Deve ter 8 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{8}$/.test(value)) {
                  return "O nº CC/BI deve ter 8 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nº de CC/BI"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 8,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.cc}
                {...field}
                {...(errors.cc && {
                  error: true,
                  helperText: errors.cc?.message,
                })}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <Controller
              name="validade_doc"
              control={control}
              rules={{
                required: { value: true, message: "Campo Obrigatório" },
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  sx={{ width: "48%", mb: 1 }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                    },
                  }}
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  label="Validade do documento"
                  value={validade}
                  onChange={handleChangeValidade}
                  renderInput={(params) => <TextField {...params} />}
                  inputRef={ref}
                  error={!!errors.validade_doc}
                  {...(errors.validade_doc && {
                    error: true,
                    helperText: errors.validade_doc?.message,
                  })}
                />
              )}
            />
          </LocalizationProvider>
          <Controller
            name="email"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Email"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.email}
                {...field}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email?.message,
                })}
              />
            )}
          />
          <Controller
            name="iban"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]*$/,
                message: "Apenas dígitos são permitidos",
              },
              validate: (value) => {
                if (!/^\d{21}$/.test(value)) {
                  return "O IBAN deve ter 21 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="IBAN"
                inputProps={{
                  maxLength: 21,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                  startAdornment: "PT50",
                }}
                InputProps={{
                  startAdornment: "PT50",
                }}
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.iban}
                {...field}
                {...(errors.iban && {
                  error: true,
                  helperText: errors.iban?.message,
                })}
              />
            )}
          />
          <Controller
            name="checkbox_morada"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedMorada}
                      onChange={handleChangeCheckMorada}
                      color="primary"
                    />
                  }
                  label="A morada fiscal é diferente do CPE"
                />
              </FormGroup>
            )}
          />
          {checkedMorada && (
            <>
              <Controller
                name="morada"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Morada"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.morada}
                    {...field}
                    {...(errors.morada && {
                      error: true,
                      helperText: errors.morada?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="localidade"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Localidade"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.localidade}
                    {...field}
                    {...(errors.localidade && {
                      error: true,
                      helperText: errors.localidade?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="cod_postal"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                  pattern: {
                    value: /^\d{4}-\d{3}$/,
                    message: "O Código Postal deve ter o formato",
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Código Postal"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    inputProps={{
                      maxLength: 8,
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        e.target.value = e.target.value.replace(
                          /(\d{4})(?=\d)/g,
                          "$1-"
                        );
                      },
                    }}
                    error={!!errors.cod_postal}
                    {...field}
                    {...(errors.cod_postal && {
                      error: true,
                      helperText: errors.cod_postal?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="concelho"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Concelho"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.concelho}
                    {...field}
                    {...(errors.concelho && {
                      error: true,
                      helperText: errors.concelho?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="distrito"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Distrito"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    error={!!errors.distrito}
                    {...field}
                    {...(errors.distrito && {
                      error: true,
                      helperText: errors.distrito?.message,
                    })}
                  />
                )}
              />
            </>
          )}
          <Typography sx={{ mt: 2 }}>Como prefere ser contactado?</Typography>
          <Controller
            name="checkbox_contacto"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Selecione um método de contacto",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "email"}
                        onChange={() => handleChangeCheckContacto("email")}
                        value="email"
                      />
                    }
                    label="E-mail"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "contacto"}
                        onChange={() => handleChangeCheckContacto("contacto")}
                        value="contacto"
                      />
                    }
                    label="Contacto Telefónico"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContacto === "postal"}
                        onChange={() => handleChangeCheckContacto("postal")}
                        value="postal"
                      />
                    }
                    label="Correio Postal"
                  />
                </FormGroup>
                <FormHelperText sx={{ mb: 1, color: "#d32f2f" }}>
                  {errors.checkbox_contacto &&
                    errors.checkbox_contacto?.message}
                </FormHelperText>
              </>
            )}
          />
          <Controller
            name="checkbox_termos"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Tem de aceitar os termos e condições",
              },
            }}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedTermos}
                      onChange={handleChangeCheckTermos}
                      color="primary"
                    />
                  }
                  label="Aceito os termos e condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
                />
                <FormHelperText sx={{ mb: 1, color: "#d32f2f" }}>
                  {errors.checkbox_termos && errors.checkbox_termos?.message}
                </FormHelperText>
              </FormGroup>
            )}
          />
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={onNextClick}
        sx={{
          my: 2,
          backgroundColor: "#05b6b7",
          borderRadius: "25px",
          color: "white",
        }}
        size="large"
      >
        Seguinte
        <span
          style={{
            display: "flex",
          }}
        >
          <ChevronRight />
        </span>
      </Button>
    </>
  );
};

export default Dados;
