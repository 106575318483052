import Instance from "../../services/Axios";

const GetCaptions = () => async (dispatch) => {
  await Instance()
    .get(`/captions`)
    .then(({ data }) => {
      dispatch({ type: "GET_CAPTIONS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCaptions;
