/* eslint-disable no-unused-vars */
import { ChevronRight, Delete, Description } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

const Documentos = ({
  control,
  errors,
  onNextClick,
  value,
  handleChange,
  documentos,
  setDocumentos,
  handleFileChange,
  captions,
  allFilesUploaded,
  checkFilesUploaded,
}) => {
  checkFilesUploaded();
  return (
    <>
      <Typography sx={{ mb: 2 }} variant="h3">
        Carregue aqui os seguintes documentos:
      </Typography>
      {/* Comprovativo do MCP ou Certificado de Exploração emitido pela DGEG  */}
      <div>
        {Object.values(documentos.comprovativo_mcp).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
              color: "#000",
            }}
          >
            <Delete
              onClick={() =>
                setDocumentos({ ...documentos, comprovativo_mcp: {} })
              }
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.comprovativo_mcp &&
                documentos.comprovativo_mcp.file &&
                documentos.comprovativo_mcp.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button sx={{ color: "#000" }}>
              <label htmlFor="comprovativo_mcp" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description color="#000" />
                  <Typography sx={{ margin: "0px 10px", fontSize: "12px" }}>
                    Comprovativo do MCP ou Certificado de Exploração emitido
                    pela DGEG * **
                  </Typography>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="comprovativo_mcp"
              id="comprovativo_mcp"
              hidden
              onChange={(e) => handleFileChange(e, "comprovativo_mcp")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Comprovativo de IBAN */}
      <div>
        {Object.values(documentos.comprovativo_iban).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
              color: "#000",
            }}
          >
            <Delete
              onClick={() =>
                setDocumentos({ ...documentos, comprovativo_iban: {} })
              }
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.comprovativo_iban &&
                documentos.comprovativo_iban.file &&
                documentos.comprovativo_iban.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button sx={{ color: "#000" }}>
              <label htmlFor="comprovativo_iban" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <Typography sx={{ margin: "0px 10px", fontSize: "12px" }}>
                    Comprovativo do IBAN * **
                  </Typography>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="comprovativo_iban"
              id="comprovativo_iban"
              hidden
              onChange={(e) => handleFileChange(e, "comprovativo_iban")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Procuração */}
      <div>
        {Object.values(documentos.procuracao).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
              color: "#000",
            }}
          >
            <Delete
              onClick={() => setDocumentos({ ...documentos, procuracao: {} })}
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.procuracao &&
                documentos.procuracao.file &&
                documentos.procuracao.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button sx={{ color: "#000" }}>
              <label htmlFor="procuracao" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                    Procuração *
                  </p>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="procuracao"
              id="procuracao"
              hidden
              onChange={(e) => handleFileChange(e, "procuracao")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Outros documentos */}
      <div>
        {Object.values(documentos.outros_docs).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
              color: "#000",
            }}
          >
            <Delete
              onClick={() => setDocumentos({ ...documentos, outros_docs: {} })}
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.outros_docs &&
                documentos.outros_docs.file &&
                documentos.outros_docs.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button sx={{ color: "#000" }}>
              <label htmlFor="outros_docs" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                    Outros documentos *
                  </p>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="outros_docs"
              id="outros_docs"
              hidden
              onChange={(e) => handleFileChange(e, "outros_docs")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      <Typography sx={{ fontSize: 10, my: 2 }}>
        <Typography component="span" sx={{ fontWeight: "bold", fontSize: 10 }}>
          Legendas:
        </Typography>
        <br />
        {captions[4].jaf_plus_legends_text}
        {captions[4].jaf_plus_legends_desc}
        <br />
        {captions[5].jaf_plus_legends_text}
        {captions[5].jaf_plus_legends_desc}
      </Typography>
      <Controller
        name="observacoes"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextField
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Observações"
            sx={{ width: "100%", marginBottom: 1 }}
            inputRef={ref}
            error={!!errors.observacoes}
            {...field}
            {...(errors.observacoes && {
              error: true,
              helperText: errors.observacoes?.message,
            })}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (allFilesUploaded) {
            onNextClick();
          }
        }}
        sx={{
          my: 2,
          backgroundColor: "#05b6b7",
          borderRadius: "25px",
          color: "white",
        }}
        size="large"
      >
        Seguinte
        <span
          style={{
            display: "flex",
          }}
        >
          <ChevronRight />
        </span>
      </Button>
    </>
  );
};

export default Documentos;
