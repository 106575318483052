/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Assessment,
  AssessmentOutlined,
  Assignment,
  AssignmentOutlined,
  ChevronRight,
  Equalizer,
  EqualizerOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "../index.css";

const Plano = ({
  control,
  errors,
  onNextClick,
  plano,
  handleChangePlano,
  tariff_plans,
}) => {
  return (
    <>
      <Typography sx={{ mb: 2 }} variant="h3">
        Escolha o seu Plano:
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* <Box sx={{ width: "50%", flex: 1 }}>
          <Controller
            name="plano"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Button
                onClick={() => handleChangePlano("fixo")}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ color: "#00000080" }}>
                  <Grafico1 style={{ width: "200px" }} />
                  <Typography
                    sx={{ fontSize: 30, textTransform: "capitalize" }}
                  >
                    {tariff_plans[0].jaf_plus_tariff_plan_name}
                  </Typography>
                </Box>
              </Button>
            )}
          />
          {plano == "fixo" && (
            <Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                {tariff_plans[0].jaf_plus_tariff_plan_text}
              </Typography>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                {tariff_plans[0].jaf_plus_tariff_plan_desc}
              </Typography>
              <Typography sx={{ fontSize: 10, my: 2 }}>
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: 10 }}
                >
                  Legendas:
                </Typography>
                <br />
                {captions[0].jaf_plus_legends_text} -
                <span
                  style={{ fontSize: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: captions[0].jaf_plus_legends_desc,
                  }}
                />
                <br />
                {captions[1].jaf_plus_legends_text} -
                <span
                  style={{ fontSize: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: captions[1].jaf_plus_legends_desc,
                  }}
                />
                <br />
              </Typography>
            </Box>
          )}
        </Box> */}
        {tariff_plans.map((plan) => {
          const captions = JSON.parse(plan.captions);
          return (
            <Box sx={{ width: "50%", flex: 1 }}>
              <Controller
                name="plano"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Button
                    onClick={() =>
                      handleChangePlano(plan.id_jaf_plus_tariff_plan)
                    }
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ color: "#00000080" }}>
                      <img
                       alt={plan.jaf_plus_tariff_plan_name}
                        src={plan.jaf_plus_tariff_plan_image_path}
                        style={{ width: "200px" }}
                      />
                      <Typography
                        sx={{ fontSize: 30, textTransform: "capitalize" }}
                      >
                        {plan.jaf_plus_tariff_plan_name}
                      </Typography>
                    </Box>
                  </Button>
                )}
              />
              {plano == plan.id_jaf_plus_tariff_plan && (
                <Box>
                  <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                    {plan.jaf_plus_tariff_plan_text}
                  </Typography>
                  <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                    {plan.jaf_plus_tariff_plan_desc}
                  </Typography>
                  <Typography sx={{ fontSize: 10, my: 2 }}>
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold", fontSize: 10 }}
                    >
                      Legendas:
                    </Typography>
                    <br />
                    {captions.map((caption) => {
                      return (
                        <>
                          {caption.text} -
                          <span
                            style={{ fontSize: 10 }}
                            dangerouslySetInnerHTML={{
                              __html: caption.desc,
                            }}
                          />
                          <br />
                        </>
                      );
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={onNextClick}
        sx={{
          my: 2,
          backgroundColor: "#05b6b7",
          borderRadius: "25px",
          color: "white",
        }}
        size="large"
      >
        Seguinte
        <span
          style={{
            display: "flex",
          }}
        >
          <ChevronRight />
        </span>
      </Button>
    </>
  );
};

export default Plano;
