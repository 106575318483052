/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Assessment,
  AssessmentOutlined,
  Assignment,
  ChevronRight,
  Delete,
  Description,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ptLocale from "date-fns/locale/pt";

const Resumo = ({
  control,
  errors,
  onNextClick,
  checkedTipo,
  handleChangeTipo,
  checkedMorada,
  handleChangeCheckMorada,
  checkedContacto,
  handleChangeCheckContacto,
  checkedTermos,
  handleChangeCheckTermos,
  plano,
  handleChangePlano,
  documentos,
  setDocumentos,
  handleFileChange,
  validade,
  handleChangeValidade,
  tariff_plans,
  captions,
}) => {
  const tariff_plan = tariff_plans.filter((plan) => plan.id_jaf_plus_tariff_plan == plano)
  return (
    <>
      <Controller
        name="checkbox_p_e"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTipo === "particular"}
                  disabled
                  onChange={() => handleChangeTipo("particular")}
                  value="particular"
                />
              }
              label="Particular"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTipo === "empresa"}
                  disabled
                  onChange={() => handleChangeTipo("empresa")}
                  value="empresa"
                />
              }
              label="Empresa"
            />
          </FormGroup>
        )}
      />
      {checkedTipo == "particular" && (
        <>
          <Controller
            name="nome"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome}
                {...field}
                {...(errors.nome && {
                  error: true,
                  helperText: errors.nome?.message,
                })}
              />
            )}
          />
          <Controller
            name="apelido"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Apelido"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.apelido}
                {...field}
                {...(errors.apelido && {
                  error: true,
                  helperText: errors.apelido?.message,
                })}
              />
            )}
          />
          <Controller
            name="telemovel"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Telemóvel"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.telemovel}
                {...field}
                {...(errors.telemovel && {
                  error: true,
                  helperText: errors.telemovel?.message,
                })}
              />
            )}
          />
          <Controller
            name="nif"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Deve ter 9 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{9}$/.test(value)) {
                  return "O número de telemóvel deve ter 9 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIF"
                sx={{ width: "48%", marginBottom: 1 }}
                inputProps={{
                  maxLength: 9,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.nif}
                {...field}
                {...(errors.nif && {
                  error: true,
                  helperText: errors.nif?.message,
                })}
              />
            )}
          />
          <Controller
            name="cc"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{8}$/,
                message: "Deve ter 8 dígitos numéricos.",
              },
              validate: (value) => {
                if (!/^\d{8}$/.test(value)) {
                  return "O nº CC/BI deve ter 8 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nº de CC/BI"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputProps={{
                  maxLength: 8,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                inputRef={ref}
                error={!!errors.cc}
                {...field}
                {...(errors.cc && {
                  error: true,
                  helperText: errors.cc?.message,
                })}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <Controller
              name="validade_doc"
              control={control}
              rules={{
                required: { value: true, message: "Campo Obrigatório" },
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  disabled
                  {...field}
                  sx={{ width: "48%", mb: 1 }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                    },
                  }}
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  label="Validade do documento"
                  value={validade}
                  onChange={handleChangeValidade}
                  renderInput={(params) => <TextField {...params} />}
                  inputRef={ref}
                  error={!!errors.validade_doc}
                  {...(errors.validade_doc && {
                    error: true,
                    helperText: errors.validade_doc?.message,
                  })}
                />
              )}
            />
          </LocalizationProvider>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Endereço de email inválido",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Email"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.email}
                {...field}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email?.message,
                })}
              />
            )}
          />
          <Controller
            name="iban"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]*$/,
                message: "Apenas dígitos são permitidos",
              },
              validate: (value) => {
                if (!/^\d{21}$/.test(value)) {
                  return "O IBAN deve ter 21 dígitos numéricos.";
                }
                return true; // Validation passed
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="IBAN"
                inputProps={{
                  maxLength: 21,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  },
                }}
                InputProps={{
                  startAdornment: "PT50",
                }}
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.iban}
                {...field}
                {...(errors.iban && {
                  error: true,
                  helperText: errors.iban?.message,
                })}
              />
            )}
          />
          <Controller
            name="checkbox_morada"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedMorada}
                      onChange={handleChangeCheckMorada}
                      color="primary"
                    />
                  }
                  label="A morada fiscal é diferente do CPE"
                />
              </FormGroup>
            )}
          />
          {checkedMorada && (
            <>
              <Controller
                name="morada"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Morada"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.morada}
                    {...field}
                    {...(errors.morada && {
                      error: true,
                      helperText: errors.morada?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="localidade"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Localidade"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.localidade}
                    {...field}
                    {...(errors.localidade && {
                      error: true,
                      helperText: errors.localidade?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="cod_postal"
                control={control}
                rules={{
                  pattern: {
                    value: /^\d{4}-\d{3}$/,
                    message: "O Código Postal deve ter o formato",
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Código Postal"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    inputProps={{
                      maxLength: 8,
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        e.target.value = e.target.value.replace(
                          /(\d{4})(?=\d)/g,
                          "$1-"
                        );
                      },
                    }}
                    error={!!errors.cod_postal}
                    {...field}
                    {...(errors.cod_postal && {
                      error: true,
                      helperText: errors.cod_postal?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="concelho"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Concelho"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.concelho}
                    {...field}
                    {...(errors.concelho && {
                      error: true,
                      helperText: errors.concelho?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="distrito"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Distrito"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    error={!!errors.distrito}
                    {...field}
                    {...(errors.distrito && {
                      error: true,
                      helperText: errors.distrito?.message,
                    })}
                  />
                )}
              />
            </>
          )}
          <Typography sx={{ mt: 2 }} variant="body2">
            Como prefere ser contactado?
          </Typography>
          <Controller
            name="checkbox_contacto"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "email"}
                      onChange={() => handleChangeCheckContacto("email")}
                      value="email"
                    />
                  }
                  label="E-mail"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "contacto"}
                      onChange={() => handleChangeCheckContacto("contacto")}
                      value="contacto"
                    />
                  }
                  label="Contacto Telefónico"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "postal"}
                      onChange={() => handleChangeCheckContacto("postal")}
                      value="postal"
                    />
                  }
                  label="Correio Postal"
                />
              </FormGroup>
            )}
          />
          <Controller
            name="checkbox_termos"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedTermos}
                      onChange={handleChangeCheckTermos}
                      color="primary"
                    />
                  }
                  label="Aceito os termos e condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
                />
              </FormGroup>
            )}
          />
        </>
      )}
      {checkedTipo == "empresa" && (
        <>
          <Controller
            name="nome_empresa"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome Empresa"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome_empresa}
                {...field}
                {...(errors.nome_empresa && {
                  error: true,
                  helperText: errors.nome_empresa?.message,
                })}
              />
            )}
          />
          <Controller
            name="cod_certidao_permanente"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Código da certidão permanente"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.cod_certidao_permanente}
                {...field}
                {...(errors.cod_certidao_permanente && {
                  error: true,
                  helperText: errors.cod_certidao_permanente?.message,
                })}
              />
            )}
          />
          <Controller
            name="nipc"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIPC"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nirc}
                {...field}
                {...(errors.nirc && {
                  error: true,
                  helperText: errors.nirc?.message,
                })}
              />
            )}
          />
          <Controller
            name="cae"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="CAE"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.cae}
                {...field}
                {...(errors.cae && {
                  error: true,
                  helperText: errors.cae?.message,
                })}
              />
            )}
          />
          <Controller
            name="nome_rep_legal"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nome do representante legal"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.nome_rep_legal}
                {...field}
                {...(errors.nome_rep_legal && {
                  error: true,
                  helperText: errors.nome_rep_legal?.message,
                })}
              />
            )}
          />
          <Controller
            name="telemovel"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              pattern: {
                value: /^[0-9]{9}$/,
                message: "O número de telemóvel deve ter 9 dígitos numéricos.",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Telemóvel"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  },
                }}
                inputRef={ref}
                error={!!errors.telemovel}
                {...field}
                {...(errors.telemovel && {
                  error: true,
                  helperText: errors.telemovel?.message,
                })}
              />
            )}
          />
          <Controller
            name="nif"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="NIF"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.nif}
                {...field}
                {...(errors.nif && {
                  error: true,
                  helperText: errors.nif?.message,
                })}
              />
            )}
          />
          <Controller
            name="cc"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Nº de CC/BI"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.cc}
                {...field}
                {...(errors.cc && {
                  error: true,
                  helperText: errors.cc?.message,
                })}
              />
            )}
          />
          <Controller
            name="validade_doc"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Validade de documento"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.validade_doc}
                {...field}
                {...(errors.validade_doc && {
                  error: true,
                  helperText: errors.validade_doc?.message,
                })}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Email"
                sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                inputRef={ref}
                error={!!errors.email}
                {...field}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email?.message,
                })}
              />
            )}
          />
          <Controller
            name="iban"
            control={control}
            rules={{
              required: { value: true, message: "Campo Obrigatório" },
              maxLength: {
                value: 25,
                message: "Máximo de 25 caracteres permitidos",
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Apenas dígitos são permitidos",
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled
                variant="standard"
                fullWidth
                autoComplete="off"
                label="IBAN"
                sx={{ width: "48%", marginBottom: 1 }}
                inputRef={ref}
                error={!!errors.iban}
                {...field}
                inputProps={{ maxLength: 25 }} // Set the maximum length of the input field
                {...(errors.iban && {
                  error: true,
                  helperText: errors.iban?.message,
                })}
              />
            )}
          />
          <Controller
            name="checkbox_morada"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedMorada}
                      onChange={handleChangeCheckMorada}
                      color="primary"
                    />
                  }
                  label="A morada fiscal é diferente do CPE"
                />
              </FormGroup>
            )}
          />
          {checkedMorada && (
            <>
              <Controller
                name="morada"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Morada"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.morada}
                    {...field}
                    {...(errors.morada && {
                      error: true,
                      helperText: errors.morada?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="localidade"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Localidade"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.localidade}
                    {...field}
                    {...(errors.localidade && {
                      error: true,
                      helperText: errors.localidade?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="cod_postal"
                control={control}
                render={({ field }) => {
                  const { onChange, value } = field;

                  const handleChange = (e) => {
                    const inputValue = e.target.value;
                    // Remove any non-digit characters
                    const digitsOnly = inputValue.replace(/\D/g, "");
                    // Insert hyphen after the fourth digit
                    const formattedValue = digitsOnly.replace(/(\d{4})/, "$1-");
                    // Update the input value
                    onChange(formattedValue);
                  };

                  return (
                    <TextField
                      disabled
                      variant="standard"
                      fullWidth
                      autoComplete="off"
                      label="Código Postal"
                      sx={{ width: "48%", marginBottom: 1 }}
                      value={value}
                      onChange={handleChange}
                      error={!!errors.cod_postal}
                      helperText={
                        errors.cod_postal ? errors.cod_postal.message : null
                      }
                    />
                  );
                }}
              />
              <Controller
                name="concelho"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Concelho"
                    sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
                    inputRef={ref}
                    error={!!errors.concelho}
                    {...field}
                    {...(errors.concelho && {
                      error: true,
                      helperText: errors.concelho?.message,
                    })}
                  />
                )}
              />
              <Controller
                name="distrito"
                control={control}
                rules={{
                  required: { value: true, message: "Campo Obrigatório" },
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    disabled
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    label="Distrito"
                    sx={{ width: "48%", marginBottom: 1 }}
                    inputRef={ref}
                    error={!!errors.distrito}
                    {...field}
                    {...(errors.distrito && {
                      error: true,
                      helperText: errors.distrito?.message,
                    })}
                  />
                )}
              />
            </>
          )}
          <Typography sx={{ mt: 2 }}>Como prefere ser contactado?</Typography>
          <Controller
            name="checkbox_contacto"
            control={control}
            rules={{ required: { value: true, message: "Campo Obrigatório" } }}
            render={({ field: { ref, ...field } }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "email"}
                      onChange={() => handleChangeCheckContacto("email")}
                      value="email"
                    />
                  }
                  label="E-mail"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "contacto"}
                      onChange={() => handleChangeCheckContacto("contacto")}
                      value="contacto"
                    />
                  }
                  label="Contacto Telefónico"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedContacto === "postal"}
                      onChange={() => handleChangeCheckContacto("postal")}
                      value="postal"
                    />
                  }
                  label="Correio Postal"
                />
              </FormGroup>
            )}
          />
          <Controller
            name="checkbox_termos"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={checkedTermos}
                      onChange={handleChangeCheckTermos}
                      color="primary"
                    />
                  }
                  label="Aceito os termos e condições legais de utilização dos meus dados pessoais para fins relacionados exclusivamente à minha adesão."
                />
              </FormGroup>
            )}
          />
        </>
      )}
      <Typography sx={{ my: 2 }}>Dados da UPAC</Typography>
      <Controller
        name="n_registo"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            placeholder="Verificar no certificado MCP"
            fullWidth
            autoComplete="off"
            label="Nº Registo"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.n_registo}
            {...field}
            {...(errors.n_registo && {
              error: true,
              helperText: errors.n_registo?.message,
            })}
          />
        )}
      />
      <Controller
        name="potencia"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          pattern: {
            value: /^[0-9]*$/,
            message: "Apenas dígitos são permitidos",
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Potência de injeção na RESP (kVA)"
            sx={{ width: "48%", marginBottom: 1 }}
            inputProps={{
              onInput: (e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
              },
            }}
            inputRef={ref}
            error={!!errors.potencia}
            {...field}
            {...(errors.potencia && {
              error: true,
              helperText: errors.potencia?.message,
            })}
          />
        )}
      />
      <Controller
        name="cpe_consumo"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          validate: (value) => {
            if (!/^.{14}$/.test(value)) {
              return "O CPE de consumo deve ter 14 caracteres.";
            }
            return true; // Validation passed
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="CPE de consumo"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            inputProps={{
              maxLength: 14,
            }}
            InputProps={{
              startAdornment: "PT0002",
            }}
            error={!!errors.cpe_consumo}
            {...field}
            {...(errors.cpe_consumo && {
              error: true,
              helperText: errors.cpe_consumo?.message,
            })}
          />
        )}
      />
      <Controller
        name="cpe_producao"
        control={control}
        rules={{
          required: { value: true, message: "Campo Obrigatório" },
          validate: (value) => {
            if (!/^.{14}$/.test(value)) {
              return "O CPE de consumo deve ter 14 caracteres.";
            }
            return true; // Validation passed
          },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="CPE de produção"
            sx={{ width: "48%", marginBottom: 1 }}
            inputRef={ref}
            inputProps={{
              maxLength: 14,
            }}
            InputProps={{
              startAdornment: "PT0002",
            }}
            error={!!errors.cpe_producao}
            {...field}
            {...(errors.cpe_producao && {
              error: true,
              helperText: errors.cpe_producao?.message,
            })}
          />
        )}
      />
      <Controller
        name="morada_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Morada"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.morada}
            {...field}
            {...(errors.morada && {
              error: true,
              helperText: errors.morada?.message,
            })}
          />
        )}
      />
      <Controller
        name="localidade_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Localidade"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.localidade}
            {...field}
            {...(errors.localidade && {
              error: true,
              helperText: errors.localidade?.message,
            })}
          />
        )}
      />
      <Controller
        name="cod_postal_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Código Postal"
            sx={{ width: "48%", marginBottom: 1 }}
            inputRef={ref}
            error={!!errors.cod_postal}
            {...field}
            {...(errors.cod_postal && {
              error: true,
              helperText: errors.cod_postal?.message,
            })}
          />
        )}
      />
      <Controller
        name="concelho_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Concelho"
            sx={{ width: "48%", marginBottom: 1, marginRight: "4%" }}
            inputRef={ref}
            error={!!errors.concelho}
            {...field}
            {...(errors.concelho && {
              error: true,
              helperText: errors.concelho?.message,
            })}
          />
        )}
      />
      <Controller
        name="distrito_upac"
        control={control}
        rules={{ required: { value: true, message: "Campo Obrigatório" } }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled
            variant="standard"
            fullWidth
            autoComplete="off"
            label="Distrito"
            sx={{ width: "48%", marginBottom: 1 }}
            inputRef={ref}
            error={!!errors.distrito}
            {...field}
            {...(errors.distrito && {
              error: true,
              helperText: errors.distrito?.message,
            })}
          />
        )}
      />
      <Typography sx={{ my: 2 }}>O seu Plano:</Typography>

      {tariff_plan.map((plan) => {
        const captions = JSON.parse(plan.captions);
        return (
          <Box sx={{ width: "50%", flex: 1 }}>
            <Controller
              name="plano"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Button
                  onClick={() =>
                    handleChangePlano(plan.id_jaf_plus_tariff_plan)
                  }
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ color: "#00000080" }}>
                    <img
                      alt={plan.jaf_plus_tariff_plan_name}
                      src={plan.jaf_plus_tariff_plan_image_path}
                      style={{ width: "200px" }}
                    />
                    <Typography
                      sx={{ fontSize: 30, textTransform: "capitalize" }}
                    >
                      {plan.jaf_plus_tariff_plan_name}
                    </Typography>
                  </Box>
                </Button>
              )}
            />
            <Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                {plan.jaf_plus_tariff_plan_text}
              </Typography>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                {plan.jaf_plus_tariff_plan_desc}
              </Typography>
              <Typography sx={{ fontSize: 10, my: 2 }}>
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: 10 }}
                >
                  Legendas:
                </Typography>
                <br />
                {captions.map((caption) => {
                  return (
                    <>
                      {caption.text} -
                      <span
                        style={{ fontSize: 10 }}
                        dangerouslySetInnerHTML={{
                          __html: caption.desc,
                        }}
                      />
                      <br />
                    </>
                  );
                })}
              </Typography>
            </Box>
          </Box>
        );
      })}
      <Typography sx={{ my: 2 }}>Documentos carregados:</Typography>
      {/* Comprovativo do MCP ou Certificado de Exploração emitido pela DGEG  */}
      <div>
        {Object.values(documentos.comprovativo_mcp).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
            }}
          >
            <Delete
              onClick={() =>
                setDocumentos({ ...documentos, comprovativo_mcp: {} })
              }
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.comprovativo_mcp &&
                documentos.comprovativo_mcp.file &&
                documentos.comprovativo_mcp.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button>
              <label htmlFor="comprovativo_mcp" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <Typography sx={{ margin: "0px 10px", fontSize: "12px" }}>
                    Comprovativo do MCP ou Certificado de Exploração emitido
                    pela DGEG * **
                  </Typography>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="comprovativo_mcp"
              id="comprovativo_mcp"
              hidden
              onChange={(e) => handleFileChange(e, "comprovativo_mcp")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Comprovativo de IBAN */}
      <div>
        {Object.values(documentos.comprovativo_iban).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
            }}
          >
            <Delete
              onClick={() =>
                setDocumentos({ ...documentos, comprovativo_iban: {} })
              }
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.comprovativo_iban &&
                documentos.comprovativo_iban.file &&
                documentos.comprovativo_iban.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button>
              <label htmlFor="comprovativo_iban" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <Typography sx={{ margin: "0px 10px", fontSize: "12px" }}>
                    Comprovativo do IBAN * **
                  </Typography>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="comprovativo_iban"
              id="comprovativo_iban"
              hidden
              onChange={(e) => handleFileChange(e, "comprovativo_iban")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Procuração */}
      <div>
        {Object.values(documentos.procuracao).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
            }}
          >
            <Delete
              onClick={() => setDocumentos({ ...documentos, procuracao: {} })}
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.procuracao &&
                documentos.procuracao.file &&
                documentos.procuracao.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button>
              <label htmlFor="procuracao" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                    Procuração *
                  </p>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="procuracao"
              id="procuracao"
              hidden
              onChange={(e) => handleFileChange(e, "procuracao")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      {/* Outros documentos */}
      <div>
        {Object.values(documentos.outros_docs).length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px",
            }}
          >
            <Delete
              onClick={() => setDocumentos({ ...documentos, outros_docs: {} })}
            />
            <p
              style={{
                marginLeft: "10px",
              }}
            >
              {documentos &&
                documentos.outros_docs &&
                documentos.outros_docs.file &&
                documentos.outros_docs.file.name}
            </p>
          </div>
        ) : (
          <>
            <Button>
              <label htmlFor="outros_docs" className="custom-file-upload">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                >
                  <Description />
                  <p style={{ margin: "0px 10px", fontSize: "12px" }}>
                    Outros documentos *
                  </p>
                </div>
              </label>
            </Button>
            <input
              type="file"
              name="outros_docs"
              id="outros_docs"
              hidden
              onChange={(e) => handleFileChange(e, "outros_docs")}
              // multiple
              accept=".jpg,.jpeg,.pdf"
            />
          </>
        )}
      </div>
      <Typography sx={{ fontSize: 10, my: 2 }}>
        <Typography component="span" sx={{ fontWeight: "bold", fontSize: 10 }}>
          Legendas:
        </Typography>
        <br />
        {captions[4].jaf_plus_legends_text}
        {captions[4].jaf_plus_legends_desc}
        <br />
        {captions[5].jaf_plus_legends_text}
        {captions[5].jaf_plus_legends_desc}
      </Typography>
    </>
  );
};

export default Resumo;
