import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { infoReducer } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import notificationReducer from "./reducers/notificationsReducer";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  info: infoReducer,
  notifications: notificationReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(store);
