/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./index.css";
import "../css/topNav.css";
import logo from "../css/images/logo.png";
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dados from "./forms/dados";
import UPAC from "./forms/upac";
import Plano from "./forms/plano";
import Documentos from "./forms/documentos";
import Resumo from "./forms/resumo";
import { FormProvider, useForm } from "react-hook-form";
import { ChevronRight } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import GetCaptions from "../redux/actions/getCaptions";
import GetTariffPlans from "../redux/actions/getTariffPlans";
import BASE_URL from "../services/BASE_URL";
import ShowNotification from "../redux/actions/showNotification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CustomTab(props) {
  return (
    <Tab
      disableRipple
      {...props}
      className="AntTab-root"
    />
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Form = () => {
  const dispatch = useDispatch();
  // => Selectors
  const tariff_plans = useSelector((state) => state.info.tariff_plan);
  const captions = useSelector((state) => state.info.captions);

  // => Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // => Alert
  const [alertState, setAlertState] = useState(false);

  // => Hook Form
  const methods = useForm({
    defaultValues: {
      //Dados Particular
      checkbox_p_e: "particular",
      nome: "",
      apelido: "",
      telemovel: "",
      nif: "",
      cc: "",
      validade_doc: "",
      email: "",
      iban: "",
      checkbox_morada: "",
      morada: "",
      localidade: "",
      cod_postal: "",
      concelho: "",
      distrito: "",
      checkbox_contacto: "",
      checkbox_termos: "",
      // Dados Empresa
      nome_empresa: "",
      cod_certidao_permanente: "",
      nirc: "",
      cae: "",
      nome_rep_legal: "",
      //Dados UPAC
      n_registo: "",
      potencia: "",
      cpe_consumo: "",
      cpe_producao: "",
      morada_upac: "",
      localidade_upac: "",
      cod_postal_upac: "",
      concelho_upac: "",
      distrito_upac: "",
      //Dados Plano
      plano: "",
      //Dados Documentos
      observacoes: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    trigger,
  } = methods;

  // => Tabs Navigation
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = async (event, newValue) => {
    if (currentTab < newValue) {
      if (currentTab === 0) {
        methods.handleSubmit((data) => {
          if (checkedTermos) {
            setCurrentTab((prevTab) => prevTab + 1);
          } else {
            return;
          }
        })();
      } else if (currentTab === 1) {
        methods.handleSubmit((data) => {
          setCurrentTab((prevTab) => prevTab + 1);
        })();
      } else if (currentTab === 2) {
        methods.handleSubmit((data) => {
          setCurrentTab((prevTab) => prevTab + 1);
        })();
      } else if (currentTab === 3) {
        methods.handleSubmit((data) => {
          setCurrentTab((prevTab) => prevTab + 1);
        })();
      }
    } else {
      setCurrentTab(newValue);
    }
  };

  const onNextClick = () => {
    if (currentTab === 0) {
      methods.handleSubmit((data) => {
        if (checkedTermos) {
          setCurrentTab((prevTab) => prevTab + 1);
        } else {
          return;
        }
      })();
    } else if (currentTab === 1) {
      methods.handleSubmit((data) => {
        setCurrentTab((prevTab) => prevTab + 1);
      })();
    } else if (currentTab === 2) {
      methods.handleSubmit((data) => {
        setCurrentTab((prevTab) => prevTab + 1);
      })();
    } else if (currentTab === 3) {
      methods.handleSubmit((data) => {
        setCurrentTab((prevTab) => prevTab + 1);
      })();
    }
  };

  // => Checkbox Particular & Empresa
  const [checkedTipo, setCheckedTipo] = useState("particular");

  const handleChangeTipo = (value) => {
    setCheckedTipo(value);
    methods.setValue("checkbox_p_e", value);
  };

  // => Checkbox Morada
  const [checkedMorada, setCheckedMorada] = useState(false);

  const handleChangeCheckMorada = (event) => {
    setCheckedMorada(event.target.checked);
    methods.setValue("checkbox_morada", event.target.checked);
  };

  // => Checkbox Contacto
  const [checkedContacto, setCheckedContacto] = useState("");

  const handleChangeCheckContacto = (value) => {
    setCheckedContacto(value);
    methods.setValue("checkbox_contacto", value);
  };

  // => Checkbox Termos
  const [checkedTermos, setCheckedTermos] = useState(false);

  const handleChangeCheckTermos = (event) => {
    setCheckedTermos(event.target.checked);
    methods.setValue("checkbox_termos", event.target.checked);
  };

  // => Checkbox Plano
  const [plano, setPlano] = useState("");

  const handleChangePlano = (value) => {
    setPlano(value);
    methods.setValue("plano", value);
  };

  // => Data validade
  const [validade, setValidade] = useState(null);

  const handleChangeValidade = (value) => {
    setValidade(value);
    methods.setValue("validade_doc", value);
  };

  // => Handle Documentos
  const [documentos, setDocumentos] = useState({
    comprovativo_mcp: {},
    comprovativo_iban: {},
    procuracao: {},
    outros_docs: {},
  });

  const handleFileChange = (e, tipoDocumento) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      var url = reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (tipoDocumento === "comprovativo_mcp") {
          setDocumentos({
            ...documentos,
            comprovativo_mcp: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        } else if (tipoDocumento === "comprovativo_iban") {
          setDocumentos({
            ...documentos,
            comprovativo_iban: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        } else if (tipoDocumento === "procuracao") {
          setDocumentos({
            ...documentos,
            procuracao: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        } else if (tipoDocumento === "outros_docs") {
          setDocumentos({
            ...documentos,
            outros_docs: {
              imgSrc: [reader.result],
              file: file,
            },
          });
        }
      };
    }
    checkFilesUploaded();
    e.target.value = "";
  };

  const [allFilesUploaded, setAllFilesUploaded] = useState(false);

  console.log(allFilesUploaded);

  const checkFilesUploaded = () => {
    // Check if all required files are uploaded
    const requiredFiles = ["comprovativo_mcp", "comprovativo_iban"];
    const allUploaded = requiredFiles.every(
      (fileKey) => Object.values(documentos[fileKey]).length > 0
    );
    setAllFilesUploaded(allUploaded);
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    const formatedDate = moment(data.validade_documento).format("yyyy-MM-DD");

    const tipoCliente = () => {
      if (data.checkbox_p_e === "particular") {
        return 1;
      } else if (data.checkbox_p_e === "empresa") {
        return 2;
      }
    };

    const preferenciaContacto = (value) => {
      if (data.checkbox_contacto === value) {
        return true;
      } else {
        return false;
      }
    };

    const checkMorada = () => {
      if (data.checkbox_morada) {
        return true;
      } else {
        return false;
      }
    };

    const dados = {
      //Dados Particular
      tipo_cliente: tipoCliente(),
      nome: data.nome,
      apelido: data.apelido,
      telemovel: data.telemovel,
      nif: data.nif,
      cc: data.cc,
      validade_doc: formatedDate,
      email: data.email,
      iban: "PT50" + data.iban,
      checked_morada: checkMorada(),
      morada: data.morada,
      localidade: data.localidade,
      cod_postal: data.cod_postal,
      concelho: data.concelho,
      distrito: data.distrito,
      termos: data.checkbox_termos,
      contactoemail: preferenciaContacto("email"),
      contactotel: preferenciaContacto("contacto"),
      contactopostal: preferenciaContacto("postal"),
      // Dados Empresa
      nome_empresa: data.nome_empresa,
      cod_certidao_permanente: data.cod_certidao_permanente,
      nipc: data.nipc,
      cae: data.cae,
      nome_rep_legal: data.nome_rep_legal,
      //Dados UPAC
      n_registo: data.n_registo,
      potencia: data.potencia,
      cpe_consumo: "PT0002" + data.cpe_consumo,
      cpe_producao: "PT0002" + data.cpe_producao,
      morada_upac: data.morada_upac,
      localidade_upac: data.localidade_upac,
      cod_postal_upac: data.cod_postal_upac,
      concelho_upac: data.concelho_upac,
      distrito_upac: data.distrito_upac,
      //Dados Plano
      plano: data.plano,
      //Dados Documentos
      observacoes: data.observacoes,
    };

    const comprovativoMcp = documentos.comprovativo_mcp.file;
    const comprovativoIban = documentos.comprovativo_iban.file;
    const procuracao = documentos.procuracao.file;
    const outrosDocumentos = documentos.outros_docs.file;

    const formdata = new FormData();
    formdata.append("dados", JSON.stringify(dados));
    formdata.append("comprovativoMcp", comprovativoMcp);
    formdata.append("comprovativoIban", comprovativoIban);
    formdata.append("procuracao", procuracao);
    formdata.append("outrosDocumentos", outrosDocumentos);

    console.log([...formdata]);
    const message = {
      isOpen: true,
      message: "Formulário submetido com sucesso!!",
      title: "Formulário submetido",
      type: "success",
    };
    axios
      .post(BASE_URL, formdata)
      .then((data) => {
        console.log(data);
        handleOpen();
        setLoading(false);
        // dispatch(ShowNotification(message));
      })
      .catch((error) => {
        console.error("Error occurred during POST request:", error);
        // Handle the error here, such as showing an error message to the user
      });
  };

  const resetForm = () => {
    reset();
    setCheckedTipo("particular");
    setValidade(null);
    setCheckedMorada(false);
    setCheckedContacto("");
    setCheckedTermos(false);
    setPlano("");
    setDocumentos({
      comprovativo_mcp: {},
      comprovativo_iban: {},
      procuracao: {},
      outros_docs: {},
    });
  };

  const fetchCaptions = () => {
    dispatch(GetCaptions());
  };

  const fetchTariffPlans = () => {
    dispatch(GetTariffPlans());
  };

  const fetchInfo = () => {
    fetchCaptions();
    fetchTariffPlans();
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#05b6b7" }}
          >
            Formulário enviado com sucesso!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Obrigado por preferir os nossos serviços!
            <br />
            Os seus dados foram enviados. Irá ser contactado pela nossa equipa
            comercial através do seu e-mail:
            <b style={{ fontSize: "1rem", marginLeft: 2 }}>
              {methods.getValues("email")}
            </b>
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resetForm();
                setCurrentTab((prevTab) => prevTab - 4);
                handleClose();
              }}
              sx={{
                my: 2,
                mr: 1,
                backgroundColor: "#05b6b7",
                borderRadius: "25px",
                color: "white",
              }}
              size="large"
            >
              Nova Adesão
            </Button>
            <a href="https://jafplus.pt/particulares/">
              <Button
                color="primary"
                type="submit"
                sx={{
                  my: 2,
                  borderRadius: "25px",
                  border: "2px solid #05b6b7",
                  color: "black",
                }}
                size="large"
              >
                Voltar a Jaf Plus
              </Button>
            </a>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: "block", flexGrow: 1, width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "75px",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <img src={logo} alt="" />
        </Box>
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            "@media (max-width: 800px)": {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AppBar
                position="static"
                elevation={0}
                sx={{
                  backgroundColor: "white",
                  color: "grey",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                  sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    "@media (max-width: 800px)": {
                      // Ensure that the variant and scrollButtons props are applied in mobile view
                      variant: "scrollable",
                      scrollButtons: "auto",
                      justifyContent: "center",
                    },
                  }}
                >
                  <CustomTab label="Dados pessoais" {...a11yProps(0)} />
                  <CustomTab label="UPAC" {...a11yProps(1)} />
                  <CustomTab label="Plano" {...a11yProps(2)} />
                  <CustomTab label="Documentos" {...a11yProps(3)} />
                  <CustomTab label="Resumo" {...a11yProps(4)} />
                </Tabs>
                <CustomTabPanel
                  value={currentTab}
                  index={0}
                  style={{ margin: "4%" }}
                >
                  <Dados
                    control={control}
                    errors={errors}
                    onNextClick={onNextClick}
                    checkedTipo={checkedTipo}
                    handleChangeTipo={handleChangeTipo}
                    checkedMorada={checkedMorada}
                    handleChangeCheckMorada={handleChangeCheckMorada}
                    checkedContacto={checkedContacto}
                    handleChangeCheckContacto={handleChangeCheckContacto}
                    checkedTermos={checkedTermos}
                    handleChangeCheckTermos={handleChangeCheckTermos}
                    validade={validade}
                    handleChangeValidade={handleChangeValidade}
                  />
                </CustomTabPanel>
                <CustomTabPanel
                  value={currentTab}
                  index={1}
                  style={{ margin: "4%" }}
                >
                  <UPAC
                    control={methods.control}
                    errors={methods.formState.errors}
                    onNextClick={onNextClick}
                  />
                </CustomTabPanel>
                <CustomTabPanel
                  value={currentTab}
                  index={2}
                  style={{ margin: "4%" }}
                >
                  <Plano
                    control={control}
                    errors={errors}
                    onNextClick={onNextClick}
                    plano={plano}
                    handleChangePlano={handleChangePlano}
                    tariff_plans={tariff_plans}
                  />
                </CustomTabPanel>
                <CustomTabPanel
                  value={currentTab}
                  index={3}
                  style={{ margin: "4%" }}
                >
                  <Documentos
                    control={control}
                    errors={errors}
                    onNextClick={onNextClick}
                    documentos={documentos}
                    setDocumentos={setDocumentos}
                    handleFileChange={handleFileChange}
                    captions={captions}
                    allFilesUploaded={allFilesUploaded}
                    checkFilesUploaded={checkFilesUploaded}
                  />
                </CustomTabPanel>
                <CustomTabPanel
                  value={currentTab}
                  index={4}
                  style={{ margin: "4%" }}
                >
                  <Resumo
                    control={control}
                    errors={errors}
                    onNextClick={onNextClick}
                    checkedTipo={checkedTipo}
                    handleChangeTipo={handleChangeTipo}
                    checkedMorada={checkedMorada}
                    handleChangeCheckMorada={handleChangeCheckMorada}
                    checkedContacto={checkedContacto}
                    handleChangeCheckContacto={handleChangeCheckContacto}
                    checkedTermos={checkedTermos}
                    handleChangeCheckTermos={handleChangeCheckTermos}
                    plano={plano}
                    handleChangePlano={handleChangePlano}
                    documentos={documentos}
                    setDocumentos={setDocumentos}
                    handleFileChange={handleFileChange}
                    validade={validade}
                    handleChangeValidade={handleChangeValidade}
                    tariff_plans={tariff_plans}
                    captions={captions}
                  />
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      my: 2,
                      backgroundColor: "#05b6b7",
                      borderRadius: "25px",
                      color: "white",
                    }}
                    size="large"
                  >
                    {loading ? "Pedido Enviado" : "Enviar Pedido"}
                    <span
                      style={{
                        paddingRight: 0,
                        paddingLeft: 2,
                        display: "flex",
                      }}
                    >
                      {loading ? null : <ChevronRight />}
                    </span>
                  </Button>
                </CustomTabPanel>
              </AppBar>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
