import infoSelector from "../selectors/infoSelector";

export default function infoReducer(state = infoSelector, { type, payload }) {
  switch (type) {
    case "GET_TARIFF_PLANS": {
      return {
        ...state,
        tariff_plan: payload,
      };
    }
    case "GET_CAPTIONS": {
      return {
        ...state,
        captions: payload,
      };
    }
    default:
      return state;
  }
}
