import Instance from "../../services/Axios";

const GetTariffPlans = () => async (dispatch) => {
  await Instance()
    .get(`/tariffplans`)
    .then(({ data }) => {
      dispatch({ type: "GET_TARIFF_PLANS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTariffPlans;
