import { Alert, AlertTitle, Snackbar } from "@mui/material";
import Form from "./components";
import "./css/app.css";
import Logo from "./css/images/img-icone-jafplus.png";
import { useDispatch, useSelector } from "react-redux";
import ClearNotification from "./redux/actions/clearNotification";

const App = () => {
  const dispatch = useDispatch();
  const snack = useSelector((state) => state.notifications.success);
  function handleClose(event, reason) {
    if (reason === "clickway") {
      return;
    }
    dispatch(ClearNotification());
  }
  return (
    <div className="App">
      <div className="app-left">
        <Form />
      </div>
      <div className="app-right">
        <div>
          <img src={Logo} alt="" className="logo-right" />
        </div>
        <div className="text-right">
          <h2>Somos a energia que o acompanha na sua casa ou na sua empresa</h2>
          <p>comercial@jafplus.pt</p>
          <p>apoiocliente@jafplus.pt</p>
        </div>
        <div className="text-right-footer">
          <p>Edifício SEDE Grupo JAF</p>
          <p>Rua 13 de Maio</p>
          <p>4800-530 Guimarães, Portugal</p>
          {/* <p>Rua 13 de Maio, nº1297</p>
          <p>4800-530</p> */}
        </div>
      </div>
      <Snackbar
        open={snack.isOpen}
        onClose={handleClose}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ mt: 7 }}
      >
        <Alert severity={snack && snack.type} onClose={handleClose}>
          <AlertTitle>{snack.title}</AlertTitle>
          {snack.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default App;
