const notificationsSelector = {
  success: {
    isOpen: false,
    message: "",
    title: "",
    type: "",
  },
};

export default notificationsSelector;
