import { createTheme } from "@mui/material";
import { lightBlue } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: { main: "#05b6b7" },
  },
  typography: {
    components: {
      // Override styles for the <b> tag
      b: {
        fontWeight: "bold", // Example style for <b> tag
        fontSize: 10,
        // Add more styles as needed
      },
    },
    fontFamily: "Poppins, sans-serif",
    h3: {
      marginBottom: "20px",
      color: "#05b6b7",
      fontSize: "large",
      fontWeight: 500,
    },
    body2: {
      fontSize: "small",
      color: "#05b6b7",
    },
  },
  components: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#05b6b7 !important",
        color: "white !important",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: "white !important",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white !important",
      },
    },
    MuiPickersDay: {
      day: {
        // color: lightBlue.A700,
        // color: '#05b6b7',
      },
      daySelected: {
        // backgroundColor: lightBlue['400'],
        backgroundColor: "#05b6b7",
        color: "white !important",
        "&:hover": {
          background: "#089091 !important",
        },
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        // color: lightBlue['900'],
        color: "#05b6b7",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue['400'],
        color: "#05b6b7",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#05b6b7",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "inherit", // Maintain the background color
            color: "inherit", // Maintain the text color
            WebkitTextFillColor: "inherit", // Maintain the placeholder color
            cursor: "not-allowed", // Optional: Change cursor to not-allowed
            opacity: 1, // Keep the opacity same as normal
          },
        },
        input: {
          "&.Mui-disabled": {
            backgroundColor: "inherit", // Maintain the background color
            color: "inherit", // Maintain the text color
            WebkitTextFillColor: "inherit", // Maintain the placeholder color
            opacity: 1, // Keep the opacity same as normal
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "&:before": {
              borderBottom: "1px solid currentColor", // Match the border color of the enabled state
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid currentColor", // Match the hover state border color
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "inherit", // Maintain the label color
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "inherit", // Maintain the checkbox color
            opacity: 1, // Keep the opacity same as normal
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled .MuiFormControlLabel-label": {
            color: "inherit", // Maintain the label text color
          },
        },
      },
    },
  },
});

export default theme;
